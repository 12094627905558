import hubIcon from "~/assets/apps/hub_icon.svg";

export type Permission = {
  name: string;
  label: string;
  description?: string;
};

export type App = {
  name: string;
  url: string;
  disabled?: boolean;
  icon?: {
    src: string;
    bg: string | null;
  };
  corsAllowOrigins?: string[];
  permissions: Permission[];
  order: number;
  hidden?: boolean;
};

type AppList = Readonly<{
  [key: string]: App;
}>;

const apps = {
  PROJECTS: {
    name: "Projects",
    url: "/",
    icon: {
      src: "/tools/projects.svg",
      bg: null,
    },
    permissions: [],
    order: 100,
  },
  TED: {
    name: "TED",
    url: "https://ted.hydra-newmedia.cloud/",
    icon: {
      src: "/tools/ted-logo.png",
      bg: null,
    },
    permissions: [
      {
        name: "LIMITED_EDITOR",
        label: "Limited Editor",
        description:
          "Can edit existing entries. Can not delete or create new entries.",
      },
    ],
    order: 20,
  },
  FIT: {
    name: "FIT",
    url: "https://feedback.hydra-newmedia.cloud/",
    icon: {
      src: "/tools/fit-logo-normalized.svg",
      bg: null,
    },
    permissions: [
      {
        name: "USER",
        label: "User",
        description:
          "The default FIT permission for customers. Can create issues and comment on existing issues.",
      },
    ],
    order: 10,
  },
  DISCO: {
    name: "Disco",
    url: "https://explore.discolms.app/",
    icon: {
      src: "/tools/disco-logo-normalized.svg",
      bg: null,
    },
    permissions: [],
    order: 30,
  },
  GITLAB: {
    name: "GitLab",
    url: "https://gitlab.hydra-newmedia.cloud/",
    icon: {
      src: "/tools/gitlab-icon-rgb.svg",
      bg: "bg-amber-500/20",
    },
    permissions: [],
    order: 40,
  },
  HYDRA_HUB: {
    name: "Project Links",
    url: "https://hub.hydra-newmedia.cloud/",
    permissions: [
      {
        name: "ENV:integration",
        label: "Integration",
        description: 'Grants access to the "integration" project link.',
      },
      {
        name: "ACCESS_ALL",
        label: "Access All",
        description: "Allows access to all project links.",
      },
    ],
    icon: {
      src: hubIcon,
      bg: null,
    },
    order: 0,
    hidden: true,
  },
  HY_ELEMENTS: {
    name: "Hydra Elements",
    url: "https://hub.hydra-newmedia.cloud/int/hy-elements",
    permissions: [
      {
        name: "ADMIN",
        label: "Admin",
      },
    ],
    order: 110,
    hidden: true,
  },
  AI_DEMO: {
    name: "AI Demo",
    url: "https://ai-demo.dev.hydra-newmedia.cloud",
    permissions: [
      {
        name: "ADMIN",
        label: "Admin",
      },
    ],
    order: 120,
    hidden: true,
  },
  AI_TOOLBOX: {
    name: "AI Toolbox",
    url: "https://ai-toolbox.hydra-newmedia.cloud",
    permissions: [
      {
        name: "ADMIN",
        label: "Admin",
      },
    ],
    order: 120,
    icon: {
      src: "/tools/ai-toolbox-logo.svg",
      bg: null,
    },
  },
} as AppList;

export const getAppLabel = (name: string) => {
  return apps[name]?.name || `Unknown (${name})`;
};

export const getPermissionDescription = (
  appName: string,
  permission: string
) => {
  const app = apps[appName];
  if (!app) {
    return `(${permission})`;
  }

  const perm = app.permissions.find((p) => p.name === permission);
  if (!perm) {
    return `Unknown permission (${permission})`;
  }

  return perm.description || perm.label;
};

export default apps;
